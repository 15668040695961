import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Login from "../Pages/Login";
import DashboardRoutes from "../Pages/DashboardRoutes";
const Routes = (props) => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/dashboard/">
            <DashboardRoutes />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
