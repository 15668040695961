import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles, CircularProgress } from "@material-ui/core";
import app from "../firebaseConfig";
import { Link, useHistory } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import * as Yup from "yup";
import { Formik, getActiveElement } from "formik";
import { getSingleDoc, signIn } from "../NetworkCalls/firestoreCalls";
import { collectionNames } from "../Constants/firestoreCollections";

// import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  input: {
    borderBottom: "1px solid black",
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log(user);
        const restaurant = await getSingleDoc(
          collectionNames.restaurants,
          user.uid
        );
        if (restaurant.code == 400) return await signOut(auth);
        return history.push("/dashboard");
      }
    });
  }, []);
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    try {
      console.log(values);
      setLoader(true);
      const user = await signIn(values.email, values.password);
      const isRestaurant = await getSingleDoc(
        collectionNames.restaurants,
        user.uid
      );
      console.log(isRestaurant);
      if (isRestaurant.code == 400) {
        console.log("In if errors");
        setErrors({
          email: "this user is not authenticated for restaurant",
          password: "this user is not authenticated for restaurant",
        });
        setLoader(false);
      } else {
        console.log(isRestaurant);
        resetForm();
        setLoader(false);
        history.push({ pathname: "/dashboard" });
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      setErrors({ email: err.code, password: err.code });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <div>
          <div className="header">
            <h1 className="login-heading">Restaurant Dashboard</h1>
            <div className="logo-circle"></div>
          </div>

          <div className="login-container">
            <div className="login-box">
              <p className="login-text">Login</p>

              <form onSubmit={handleSubmit}>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  className="field"
                  color="primary"
                  inputProps={{ className: classes.input }}
                  name="email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  className="field"
                  color="#890008"
                  style={{ marginTop: "0.9rem" }}
                  inputProps={{ className: classes.input }}
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {/* <input type="text" placeholder="Email" className="field" /> <br />
            <input type="Password" placeholder="Password" className="field" /> */}
                {/* <p className="forgot">Forgot your password?</p> */}

                {/* <Link to="/dashboard" style={{ textDecoration: "none" }}> */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="login-submit"
                  style={{ padding: 15, marginTop: 20 }}
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress size={23} color="primary" />
                  ) : (
                    "Login"
                  )}
                </Button>
                {/* </Link> */}
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Login;
