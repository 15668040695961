import app from "../firebaseConfig";
// import { getFirestore, getDocs, collection } from "firebase/firestore/lite";
import {
  doc,
  getDoc,
  query,
  where,
  getFirestore,
  getDocs,
  collection,
  addDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const db = getFirestore(app);
const auth = getAuth();

export const getAllDocs = async (collectionName) => {
  const col = collection(db, collectionName);
  const snapshot = await getDocs(col);

  const list = snapshot.docs.map((doc) => {
    return { ...doc.data(), selfId: doc.id };
  });
  return list;
};

export const getSingleDoc = async (collectionName, docName) => {
  const docRef = doc(db, collectionName, docName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { code: 400, message: "document not found" };
  }
};

export const getMultipleDocs = async (collectionName, query1) => {
  const q = query(
    collection(db, collectionName),
    where("couponID", "==", query1)
  );
  const querySnapshot = await getDocs(q);
  const list = querySnapshot.docs.map((doc) => doc.data());
  return list;
};

export const addSingleDoc = async (collectionName, body) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), body);
    return {
      result: "success",
      message: `document added successfully with id = ${docRef.id}`,
    };
  } catch (err) {
    return { result: "failed", message: err };
  }
};

export const addDocInSubCollection = async (
  parentCollectionName,
  docName,
  childCollectionName,
  body
) => {
  try {
    const couponRef = doc(
      db,
      parentCollectionName,
      docName,
      childCollectionName
    );
    const docRef = await addDoc(couponRef, body);
    return {
      result: "success",
      message: `document added successfully with id = ${docRef.id}`,
    };
  } catch (err) {
    return { result: "failed", message: err };
  }
};

export const getSubCollection = async (
  parentCollectionName,
  docName,
  childCollectionName
) => {
  const collectionRef = collection(
    db,
    parentCollectionName,
    docName,
    childCollectionName
  );
  const docSnap = await getDocs(collectionRef);
  const list = docSnap.docs.map((doc) => doc.data());
  return list;
};

export const addDocwithId = async (collectionName, docName, body) => {
  // console.log(collectionName, docName, body);
  try {
    const docRef = await setDoc(doc(db, collectionName, docName), body);

    return {
      result: "success",
      message: `document added successfully`,
    };
  } catch (err) {
    console.log(err);
    return { result: "failed", message: err };
  }
};

export const signIn = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      resolve(userCredentials.user);
    } catch (err) {
      reject({ code: err.code, message: err.message });
    }
  });
};
