import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Divider,
  Card,
  Fab,
  Tooltip,
  TextField,
  makeStyles,
  Button,
  Breadcrumbs,
} from "@material-ui/core";

import { Link, useLocation } from "react-router-dom";
import { AddAPhoto } from "@material-ui/icons";
import placeholder from "../Assets/placeholder.png";
import { getSingleDoc } from "../NetworkCalls/firestoreCalls";
import { collectionNames } from "../Constants/firestoreCollections";

function CouponDetails(props) {
  const location = useLocation();
  const [restaurantDetails, setRestaurantDetails] = useState({});
  useEffect(async () => {
    const restaurant = await getSingleDoc(
      collectionNames.restaurants,
      location.state.restaurantID
    );
    setRestaurantDetails(restaurant);
    console.log(restaurant);
  }, []);
  console.log(location);
  return (
    <div>
      <Grid
        style={{ marginBottom: "10px" }}
        justifyContent="space-between"
        container
        spacing={24}
      >
        <Grid item>
          <Typography variant="h4" gutterBottom display="inline">
            Coupon Details
          </Typography>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link to="/dashboard/deals">Deals</Link>
        <Typography>Coupon Details</Typography>
      </Breadcrumbs>
      <Divider style={{ margin: "38px 3px" }} my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} justifyContent="center" alignItems="flex-end">
          <div className="img-container">
            <img
              src={
                location.state.mediaURI ? location.state.mediaURI : placeholder
              }
              alt=""
              height="100%"
              width="100%"
            />
          </div>
          <div style={{ padding: "30px 2px" }}>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Title: </p>{" "}
              {location.state.couponTitle
                ? location.state.couponTitle
                : "Coupon Title"}
            </Typography>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Description: </p>{" "}
              {location.state.description
                ? location.state.description
                : "A good offer"}
            </Typography>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Restaurant: </p>{" "}
              {restaurantDetails
                ? restaurantDetails?.restaurantName
                : "KayBees"}
            </Typography>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Address: </p>{" "}
              {restaurantDetails
                ? `${restaurantDetails?.plotNo}, ${restaurantDetails?.streetName}, ${restaurantDetails?.areaName}, ${restaurantDetails?.cityName}, `
                : "Karachi"}
            </Typography>
            <Typography style={{ padding: "5px", display: "flex" }}>
              <p className="label">Validity Date: </p>{" "}
              {location.state.validity
                ? `${new Date(location.state.validity).getFullYear()}/${
                    new Date(location.state.validity).getMonth() + 1
                  }/${new Date(location.state.validity).getDate()}`
                : "01/01/2021"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default CouponDetails;
