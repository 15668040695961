import React, { useEffect } from "react";
import {
  useLocation,
  Redirect,
  BrowserRouter,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Sidebar from "../Components/Sidebar";
import Users from "./Users";
import Deals from "./Deals";
import CouponsRedeemed from "./CouponsRedeemed";
import CouponDetails from "./CouponDetails";

function DashboardRoutes(props) {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return history.push("/");
      }
    });
  }, []);

  if (location.pathname == "/dashboard") {
    return <Redirect to="/dashboard/deals" />;
  }
  return (
    <div>
      <Sidebar />
      <div className="table">
        <Switch>
          <Route exact path="/dashboard/deals" component={Deals} />
          <Route
            exact
            path="/dashboard/coupondetails"
            component={CouponDetails}
          />
          /{/* <Route exact path="/dashboard/users" component={Users} /> */}
          <Route
            exact
            path="/dashboard/couponsredeemed"
            component={CouponsRedeemed}
          />
        </Switch>
      </div>
    </div>
  );
}

export default DashboardRoutes;
